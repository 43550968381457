import FooterLP from 'components/FooterLP';
import Layout from 'components/Layout';
import { FormProvider } from 'components/pages/promotional/budma/base/FormContext';
import ApplyView from 'components/pages/promotional/budma/view/ApplyView';
import CarouselList from 'components/pages/supplier/base/CarouselList';
import BenefitItem from 'components/pages/supplier/items/BenefitItem';
import { ENUM_PAGE, ENUM_SOURCE } from 'constants/enums';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Trans } from 'gatsby-plugin-react-i18next';
import useClientInfo from 'hooks/useClientInfo';
import React, { useEffect, useRef } from 'react';
import { isMobileScreen } from 'utils/helper';

export default function TruckerPage() {
	const { client } = useClientInfo(ENUM_PAGE.FINTEQ, ENUM_SOURCE.LP2_SUPPLIER_FORM_BUDMA_2000);
	const isMobile = isMobileScreen();
	const { t } = useTranslation();
	const refApplyView = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout hideLanguage>
			<div className='sm:flex sm:items-center xl:items-start justify-end'>
				<div className='w-full sm:w-50/100 mt-10 sm:ml-30'>
					<div className='layout sm:mr-20'>
						<h1 className='sm:text-shadow pt-40 sm:pt-60 lg:pt-80 xl:pt-120'>{t('pageTrucker.h1')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h1>
						<p className='font-bold'>{t('pageTrucker.desc.bold.1')}</p>
						<p className='font-bold'>
							{t('pageTrucker.desc.bold.2')}
							<span className='text-green-1'>{t('pageTrucker.desc.bold.3')}</span>
						</p>
						<ul className='ul-custom-arrow mt-40 sm:-ml-20'>
							<li className='leading-10 my-12 text-12'>{t('pageTrucker.desc.li.1')}</li>
							<li className='leading-10 my-12 text-12'>{t('pageTrucker.desc.li.2')}</li>
							<li className='leading-10 my-12 text-12'>{t('pageTrucker.desc.li.3')}</li>
							<li className='leading-10 my-12 text-12'>{t('pageTrucker.desc.li.4')}</li>
						</ul>
					</div>
				</div>

				<div className='hidden sm:block sm:w-50/100'>
					<StaticImage src='../../../../assets/supplier/discount-your-invoice.png' alt="" placeholder='none' />
				</div>

				<div className='sm:hidden w-full my-15'>
					<StaticImage src='../../../../assets/supplier/mobile-discount-your-invoice.png' alt="" placeholder='none' />
				</div>
			</div>
				
			<div className='layout text-center'>
				<h2 className='pt-40 sm:pt-60 lg:pt-80 xl:pt-120 mb-40 sm:mb-40'>{t('pageTrucker.benefits.h2')}</h2>
				<div className='grid xl:grid-cols-2 2xl:grid-cols-3 gap-x-05 xl:gap-x-120 gap-y-10 sm:gap-y-15 items-center justify-center'>
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.1')} Image={<div tw='-mt-11'><StaticImage src='../../../../assets/supplier/benefits/account-for-0.png' alt='account-for-0' /></div>} />
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.2')} Image={<div tw='-ml-12 xl:-ml-24'><StaticImage src='../../../../assets/supplier/benefits/money-on-demand.png' alt='money-on-demand' /></div>} />
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.3')} Image={<div tw='-mt-07 -ml-07'><StaticImage src='../../../../assets/supplier/benefits/no-papers.png' alt='no-papers' /></div>} />
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.5')} Image={<div tw='mt-03'><StaticImage src='../../../../assets/supplier/benefits/one-payment.png' alt='one-payment' /></div>} />
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.7')} Image={<div tw='mt-03 -ml-15 xl:-ml-24'><StaticImage src='../../../../assets/supplier/benefits/no-use-no-pay.png' alt='no-use-no-pay' /></div>} />
					<BenefitItem isMobile={isMobile} title={t('pageTrucker.benefits.li.8')} Image={<div><StaticImage src='../../../../assets/supplier/benefits/no-suprises.png' alt='no-suprises' /></div>} />
				</div>
			</div>

			<div className='text-center'>
				<h2 className='pt-40 sm:pt-60 lg:pt-80 mb-40'>{t('pageTrucker.howItWork.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
				<div className='sm:hidden'>
					<CarouselList 
						data={[
							{ title: <Trans i18nKey="pageSupplier.howItWork.title.2" />, desc: <Trans i18nKey="pageSupplier.howItWork.desc.2" /> },
						]} 
					/>
				</div>

				<div className='hidden sm:flex layout h-90 items-center justify-center text-left'>
					{/* <div className='w-full h-full border border-grey-E rounded-10'>
						<div className='relative rounded-10 px-10 py-10'>
							<div className='text-green-1 font-bold absolute top-0 bg-light h-15 -mt-07 w-85 text-center'>{t('pageTrucker.howItWork.title.1')}</div>
							<div>{t('pageTrucker.howItWork.desc.1')}</div>
						</div>
					</div> */}
					{/* <IconButton className='mx-10' onClick={handleRefToApplyView}>
						<IconDown />
					</IconButton> */}
					<div className='w-1/2 h-full border border-grey-E rounded-10'>
						<div className='relative rounded-10 px-10 py-10'>
							<div className='text-green-1 font-bold absolute top-0 bg-light h-15 -mt-07 w-70 text-center'>{t('pageTrucker.howItWork.title.2')}</div>
							<div>{t('pageTrucker.howItWork.desc.2')}</div>
						</div>
					</div>
				</div>
			</div>

			<FormProvider maxAnimatorsAmount={1}>
				<ApplyView
					hideUserData
					client={client}
					refForward={refApplyView}
					applyTitle={t('pageTrucker.applyClient.h2')}
					applyDesc1={t('pageTrucker.applyClient.desc')}
					marketingConsent={t('app.marketingConsent')}
				/>
			</FormProvider>

			<div className='sm:hidden flex flex-col items-center justify-center'>
				<p>{t('pageTrucker.callMe')}</p>
				<a href="tel:602 300 976" className="text-12 ml-05">602 300 976, 572 004 263</a>
			</div>
			
			<FooterLP isTruckerPage />
		</Layout>
	);
}
